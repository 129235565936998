import React, { useEffect, useContext, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link } from 'react-router-dom';
import { AppSettings } from '../../config/app-settings.js';
import dishesServices from '../../api/DishesServices/disheseService.js';
import { useNavigate } from "react-router-dom";
import PosHeader from '../../components/topbar/posHeader.jsx';
import { toast, ToastContainer } from "react-toastify";

function PosOngoingOrders() {
	const navigate = useNavigate();
	const context = useContext(AppSettings);
	const [time] = useState(getTime());
	const [tableData, setTableData] = useState();
	const [posMobileSidebarToggled, setPosMobileSidebarToggled] = useState(false);
	const [selectedTable, setSelectedTable] = useState({});
	let totalAddOnsPrice = 0;
	const stallId = localStorage.getItem('stallID');
	const stallIdInt = parseInt(stallId, 10);
	const [cardNo, setcardNo] = useState();
	const [isInvalid, setIsInvalid] = useState(false);
	const [voidOrder, setVoidOrder] = useState(""); 
	const [cardValidation, setCardValidation] = useState([]);
	
	useEffect(() => {
		fetchOrders();
	}, [voidOrder]);

	const handleVoidBill = () => {
	  const voidBillState = {
		selectedTable,
		fromOngoingOrders: true, 
      };
	   
		localStorage.setItem("voidbill", JSON.stringify(selectedTable)); 
		navigate(`/pos/customer-order?-void`, { state: voidBillState });
  	};

	function checkTime(i) {
		if (i < 10) {i = "0" + i};
		return i;
	}
	
	function getTime() {
		var today = new Date();
		var h = today.getHours();
		var m = today.getMinutes();
		var a;
		m = checkTime(m);
		a = (h > 11) ? 'pm' : 'am';
		h = (h > 12) ? h - 12 : h;
	
		setTimeout(() => {
			getTime();
		}, 500);
		return h + ":" + m + a;
	}
	
	function toggleMobileSidebar(event, table) {
		event.preventDefault();
		
		setPosMobileSidebarToggled(true);
		setSelectedTable(table);
	}
	
	function dismissMobileSidebar(event) {
		event.preventDefault();
		
		setPosMobileSidebarToggled(false);
		setSelectedTable([]);
	}
	
	var getSubTotalPrice = () => {
		
		return selectedTable && selectedTable.subTotal !== undefined? selectedTable.subTotal.toFixed(2): 0;
	}
	
	var getTaxesPrice = () => {
		
		return selectedTable && selectedTable.subTotal !== undefined? selectedTable.taxesPrice.toFixed(2): 0;
	}
	
	var getTotalPrice = () =>  {
		
		return selectedTable && selectedTable.subTotal !== undefined? selectedTable.totalAmount.toFixed(2): 0;
	}
	
	function getTotalPrice(orders) {
		var total = 0;
		if (orders) {
			for (var i = 0; i < orders.length; i++) {
				total += parseFloat(orders[i].price);
			}
		}
		return total.toFixed(2);
	}

	const fetchOrders = async () => {
		try {
			if (!voidOrder) {
			setIsInvalid(false);
			return;
		}

		const foundCard = cardValidation?.find(card => card.card_Id === voidOrder || card.kcc_Id === voidOrder) || null;
  
		if (foundCard) {
			const response = await dishesServices().getAllCardOreders(foundCard.card_Id);
			const filteredOrders = response?.filter(order => order.statusChange !== "VOID" && order.stallId === stallId);
			  
			setTableData(filteredOrders);
			setIsInvalid(false); 
			
			if (response.length === 0) {
				setIsInvalid(true);
			}

		}else {
			console.error("Card not found.");
			setIsInvalid(true);
		}
 
		} catch (error) {
			console.error('Error fetching void data:', error);
			setIsInvalid(true);
		}
	};
 
	useEffect(() => {
		const ws = new WebSocket('ws://4.240.102.206:3600');

		ws.onopen = () => {
		console.log("Connected to WebSocket server");
		};

		ws.onmessage = (event) => {
		const data = JSON.parse(event.data);
		if (data.unique_key === stallId) {
			setVoidOrder(data?.decimal_value);
		  };
		};

		ws.onclose = () => {
		console.log("WebSocket connection closed");
		};

		return () => {
		ws.close();
		};
  }, []);

  const cards = async () => {
		try {
			const response = await dishesServices().getAllCards();
			setCardValidation(response.data); 
			  
		} catch (error) {
			console.error("Error fetching cards:", error);
		}
	};


	useEffect(() => {
		context.setAppHeaderNone(true);
		context.setAppSidebarNone(true);
		context.setAppContentFullHeight(true);
		context.setAppContentClass('p-0');
		
		fetchOrders();
		cards();

		return function cleanUp() {
			context.setAppHeaderNone(false);
			context.setAppSidebarNone(false);
			context.setAppContentFullHeight(false);
			context.setAppContentClass('');
		};
		
	}, []);

	const orderName = nameChange();

	function nameChange() {
		if (stallIdInt === 7) {
			return "Table";
		} else {
			return "Order No";
		}
	}

	const checkSelectedTable = checkSelectedTables();

	function checkSelectedTables() {
		if (stallIdInt === 7) {
			return "Void Order ID is";
		} else {
			return "Order No";
		}
	}

	return (
		<div className={'pos pos-vertical pos-with-header pos-with-sidebar ' + ((posMobileSidebarToggled) ? 'pos-mobile-sidebar-toggled' : '')} id="pos">
			<div className="pos-container">
			<div className="pos-header fixed-header">
				<PosHeader />
			</div>
				
				<div className="pos-content">
					<div className="pos">
						<div className="pos-container">
							<div className="pos-content h-100">
								<div className="pos-content-container p-3 h-100">
									<div className="row gx-3">
										{tableData && tableData.length > 0 ? (tableData.map((table, index) => (
											<div className="col-xl-3 col-lg-4 col-md-6 pb-3" key={index}>
												<div className={'pos-checkout-table' + 
													((selectedTable && table.transactionID === selectedTable.transactionID) ? ' selected' : '') + 
													((!table.orders && table.status !== 'Reserved') ? ' available' : '') + 
													((table.orders) ? ' in-use' : '') +
													((table.status === 'Reserved') ? ' disabled' : '')
												}>
													<a href="#/" className="pos-checkout-table-container" onClick={(event) => toggleMobileSidebar(event, table)}>
														<div className="pos-checkout-table-header">
															<div className="status"><i className="bi bi-circle-fill"></i></div>
															<div className="fw-semibold">{orderName}</div>
															<div className="fw-bold fs-1">
																{stallIdInt === 7 ? (table.tableIds === 0 ? '-' : table.tableIds) : table.transactionID}
															</div>
															<div className="fs-13px text-body text-opacity-50">
																{table.transactionItems && (<span>{table.transactionItems.length} Items</span>)}
																
															</div>
															<div className="fs-13px text-body text-opacity-50">
															{table && (<span>RS {table.totalAmount}</span>)}
																
															</div>
														</div>
													</a>
												</div>
											</div>
										))) : (
											<div className="col-12">
												No records found
											</div>
										)}
									</div>
								</div>
							</div>
			
							<div className="pos-sidebar" id="pos-sidebar">
								<div className="pos-sidebar-header">
								<div className="back-btn">
										<button type="button" onClick={dismissMobileSidebar} className="btn">
											<i className="fa fa-chevron-left"></i>
										</button>
									</div>
									<div className="col-12 mb-3 d-flex align-items-center">
									<input
										type="text"
										className={`form-control ${isInvalid ? 'is-invalid' : ''}`}
										placeholder="Enter RFID Card No."
										value={voidOrder}
										style={{
										borderColor: isInvalid ? 'red' : 'initial',
										boxShadow: isInvalid ? '0 0 10px red' : 'none',
										transition: 'box-shadow 0.3s ease, border-color 0.3s ease',
										backgroundColor: isInvalid ? 'rgba(255, 0, 0, 0.1)' : 'transparent'
										}}
										onChange={(e) => {
										const value = e.target.value;
											setVoidOrder(value);
										}}
									/>

									<button
										className="btn btn-primary ms-3"
										onClick={fetchOrders}
									>
										Search
									</button>
									</div>
							</div>

								<hr className="m-0 opacity-1" />
								<PerfectScrollbar className="pos-sidebar-body">
									<div>
										{selectedTable && selectedTable.transactionItems ? (selectedTable.transactionItems.map((order, index) => (
											<div className="pos-order py-3" key={index}>
												<div className="pos-order-product">
													<div className="flex-1">
														<div className="row">
															<div className="col-8 small">
																<div className="fs-6 fw-semibold">{ order.itemName }</div>
																{order.addOns && order.addOns.map((addOn, index) => {
																const addOnPrice = addOn.price || 0;
																totalAddOnsPrice += addOnPrice;

																return (
																	<div key={index}>
																		-{ "addOn" }: { addOn.addOn } - RS { (addOn.price).toFixed(2)  }
																	</div>
																);
															})}
															</div>
															<div className="col-1">x{ order.quantity }</div>
															<div className="col-3 text-body fw-semibold text-end">RS { (parseFloat((order.itemPrice)+totalAddOnsPrice) * order.quantity).toFixed(2) }</div>
														</div>
													</div>
												</div>
											</div>
										))) : (
											<div className="pos-order py-3">
												No records found
											</div>
										)}
									</div>
								</PerfectScrollbar>
								
								<div className="pos-sidebar-footer">
								<div className="d-flex align-items-center mb-2">
									<div>Subtotal</div>
									<div className="flex-1 text-end h6 mb-0">RS {getSubTotalPrice()}</div>
								</div>
								<div className="d-flex align-items-center">
									<div> Total Taxes </div>
									<div className="flex-1 text-end h6 mb-0">RS {getTaxesPrice()}</div>
								</div>
								<hr className="opacity-1 my-10px" />
								<div className="d-flex align-items-center mb-2">
									<div>Total</div>
									<div className="flex-1 text-end h4 mb-0">RS {getTotalPrice()}</div>
								</div>
									<div className="mt-3">
										<div className="d-flex"> 
											<button 
												className="btn btn-default flex-fill w-70px me-10px d-flex align-items-center justify-content-center" 
												onClick={handleVoidBill}
												disabled={!selectedTable || !selectedTable.transactionID}
											>
												<span>
												<i className="fa fa-receipt fa-fw fa-lg my-10px d-block mx-auto"></i>
												<span className="small fw-semibold">Void Bill</span>
												</span>
											</button>
											</div>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default PosOngoingOrders;