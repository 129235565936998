import React, { useEffect, useContext, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link } from 'react-router-dom';
import { AppSettings } from '../../../config/app-settings.js';
import dishesServices from '../../../api/DishesServices/disheseService.js';
import CounterModal from './CounterModal.js';
import taxServices from '../../../api/TaxServices/taxServices.js';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MultiPaymentModal from './MultiPaymentModal.js';
import PosHeader from '../../../components/topbar/posHeader.jsx';

function PosCounterCheckoutStall() {
	const context = useContext(AppSettings);
	const [time] = useState(getTime());
	const [filteredTableData, setFilteredTableData] = useState([]);
	const [posMobileSidebarToggled, setPosMobileSidebarToggled] = useState(false);
	const [selectedTable, setSelectedTable] = useState([]);
	const [modalShow, setModalShow] = React.useState(false);
	const [modalShow_2, setModalShow_2] = React.useState(false);
	const [searchTerm, setSearchTerm] = useState('');
	const [Taxes, setTaxes] = useState([]);
	const [paymentMethod, setPaymentMethod] = useState('');
	const [isInvalid, setIsInvalid] = useState(false);
	const stallId = localStorage.getItem('stallID');
	let TotalTaxesPrice = 0;
	var taxTotals = [];
	var cardList= [];
	const WebSocketUrl = process.env.REACT_WEBSOCKET_URL;
  
  useEffect(() => {
	addMultipleToSelectedTable(filteredTableData);
  }, [filteredTableData]);
  
  const addMultipleToSelectedTable = (newItems) => {
	setSelectedTable((prevTable) => {
	  const uniqueNewItems = newItems.filter((newItem) => {
		return !prevTable.some((existingItem) => existingItem.transactionID === newItem.transactionID);
	  });

	  return [...prevTable, ...uniqueNewItems];
	});
  };

	function checkTime(i) {
		if (i < 10) {i = "0" + i};
		return i;
	}
	
	function getTime() {
		var today = new Date();
		var h = today.getHours();
		var m = today.getMinutes();
		var a;
		m = checkTime(m);
		a = (h > 11) ? 'pm' : 'am';
		h = (h > 12) ? h - 12 : h;
	
		setTimeout(() => {
			getTime();
		}, 500);
		return h + ":" + m + a;
	}
	
	function toggleMobileSidebar(event, table) {
		event.preventDefault();
		
		setPosMobileSidebarToggled(true);
	}
	
	function dismissMobileSidebar(event) {
		event.preventDefault();
		
		setPosMobileSidebarToggled(false);
		setSelectedTable([]);
	}
	
	var getSubTotalPrice = () => {
		var  subtotal = 0;
		if(selectedTable){
		selectedTable.forEach( (sub) => {
			subtotal += sub.subTotal

		});
		return subtotal.toFixed(2) || 0;
	}}
	
	var getTotalPrice = () =>  {
		var  total = 0;
		if(selectedTable){
		selectedTable.forEach( (tot) => {
			total += tot.totalAmount

		});
		return total.toFixed(2) || 0;
	}}
	
	var getTaxesPrice = (taxType) => {
		var TaxesPrice = 0;
		var taxPrese = 0;
	
		if (selectedTable) {
			selectedTable.forEach((sele) => {
				if (sele.transactionItems) {
					sele.transactionItems.forEach((order) => {
						if (order.taxItems) {
							order.taxItems.forEach((tax) => {
								if (tax.taxType === taxType) {
									TaxesPrice += (tax.taxValue * order.quantity);
									taxPrese = tax.taxPercentage;
								}
							});
						}
					});
				}
			});
			
			TotalTaxesPrice += TaxesPrice;
	
			let existingTax = taxTotals.find(t => t.taxType === taxType);
			if (existingTax) {
				existingTax.taxTotal = TaxesPrice.toFixed(2);
			} else {
				if (taxType && TaxesPrice) {
					taxTotals.push({
						taxType: taxType,
						taxPrese: taxPrese,
						taxTotal: TaxesPrice.toFixed(2)
					});
				}
			}
			
			return TaxesPrice.toFixed(2);
		}
		
		return '0.00';
	};
	
	
	function getTTransactionIds() {
		var TransactionIdList= []
		

		if(selectedTable){
		selectedTable.forEach( (sub) => {
			TransactionIdList.push(sub.transactionID);
			if (!cardList.includes(sub.cardId)) {
				cardList.push(sub.cardId);
			  }
		});
		
	}
	return TransactionIdList;
}
	const fetchTaxes = async () => {
		try {
		  const response = await taxServices().getAllTax();
		  setTaxes(response.data); 
		  
		} catch (error) {
		  console.error('Error fetching suppliers data:', error);
		}
	  };

	  const removeTable = (cardId) => {
		const updatedTableData = selectedTable.filter(table => table.cardId !== cardId);
		setSelectedTable(updatedTableData);
	  };
	  

	  const fetchOrders = async () => {
		try {
			
			if (!stallId) {
				setIsInvalid(false);
				return;
			  }
		  const response = await dishesServices().getAllStallOreders(stallId);
		  setFilteredTableData(response);
		  setIsInvalid(false);
	  
		  if (response.length === 0) {
			setIsInvalid(true);
		  }
		} catch (error) {
		  console.error("ERROR");
		}
	  };


	  

	  const finish = () => {
		try {
		  setSelectedTable([]);
		  setSearchTerm('');
		} catch (error) {
		  console.error("ERROR");
		}
	  };
	  useEffect(() => {
		const ws = new WebSocket('ws://4.240.102.206:3600');
	
		ws.onopen = () => {
		  console.log('Connected to WebSocket server');
		};
	
		ws.onmessage = (event) => {
		  const data = JSON.parse(event.data);

		  if (data.unique_key === stallId) {
			setSearchTerm(data?.decimal_value)
		  };
		};
	
		ws.onclose = () => {
		  console.log('WebSocket connection closed');
		};
	
		return () => {
		  ws.close();
		};
	  }, []);

	useEffect(() => {
		context.setAppHeaderNone(true);
		context.setAppSidebarNone(true);
		context.setAppContentFullHeight(true);
		context.setAppContentClass('p-0');
		
		fetchTaxes();
		fetchOrders();

		return function cleanUp() {
			context.setAppHeaderNone(false);
			context.setAppSidebarNone(false);
			context.setAppContentFullHeight(false);
			context.setAppContentClass('');
		};
	}, 
	[]);
	
	return (
		<>
		<ToastContainer />
		<div className={'pos pos-vertical pos-with-header pos-with-sidebar ' + ((posMobileSidebarToggled) ? 'pos-mobile-sidebar-toggled' : '')} id="pos">
			<div className="pos-container">
			<div className="pos-header fixed-header">
				<PosHeader />
			</div>
				
				<div className="pos-content">
					<div className="pos">
						<div className="pos-container">
							<div className="pos-content h-100">
								<div className="pos-content-container p-3 h-100">
								<div className="row gx-3">
								{selectedTable && selectedTable.length > 0 ? (
									[...new Map(selectedTable.map(item => [item.cardId, item])).values()]
									.map((table, index) => (
										<div className="col-xl-3 col-lg-4 col-md-6 pb-3" key={`${table.cardId}-${index}`}>
										<div
											className={'pos-checkout-table' +
											((selectedTable && table.transactionID === selectedTable.transactionID) ? ' selected' : '') +
											((!table.orders && table.status !== 'Reserved') ? ' available' : '') +
											((table.orders) ? ' in-use' : '') +
											((table.status === 'Reserved') ? ' disabled' : '')
											}
										>
										<a href="#/" className="pos-checkout-table-container" onClick={(event) => toggleMobileSidebar(event, table)}>
											<div className="pos-checkout-table-header">
											<button 
											className="btn btn-danger btn-sm" 
											onClick={() => removeTable(table.cardId)}
											style={{position: 'absolute', top: '10px', right: '10px'}}
										>
											X
										</button>
											<div className="fw-semibold">Order No</div>
											<div className="fw-bold fs-1">{table.transactionID}</div>
											<div className="fs-13px text-body text-opacity-50">
												{table.transactionItems && (<span>{table.transactionItems.length} orders</span>)}
											</div>
											</div>
										</a>
										</div>
									</div>
									))
								) : (
									<div className="col-12">
									No records found
									</div>
								)}
								</div>
								</div>
							</div>
			
							<div className="pos-sidebar" id="pos-sidebar">
								{/* <div className="pos-sidebar-header">
									<div className="back-btn">
										<button type="button" onClick={dismissMobileSidebar} className="btn">
											<i className="fa fa-chevron-left"></i>
										</button>
									</div>
									<div className="col-12 mb-3 d-flex align-items-center">
									<input
										type="text"
										className={`form-control ${isInvalid ? 'is-invalid' : ''}`}
										placeholder="Enter Card No."
										value={searchTerm}
										style={{
										borderColor: isInvalid ? 'red' : 'initial',
										boxShadow: isInvalid ? '0 0 10px red' : 'none',
										transition: 'box-shadow 0.3s ease, border-color 0.3s ease',
										backgroundColor: isInvalid ? 'rgba(255, 0, 0, 0.1)' : 'transparent'
										}}
										onChange={(e) => {
										const value = e.target.value;
										if (/^\d*$/.test(value)) {
											setSearchTerm(value !== '' ? parseInt(value, 10) : '');
										}
										}}
									/>

									<button
										className="btn btn-primary ms-3"
										onClick={fetchOrders}
									>
										ADD
									</button>
									</div>
								</div> */}
								<hr className="m-0 opacity-1" />
								<PerfectScrollbar className="pos-sidebar-body">
								<div>
									{selectedTable ? (
										selectedTable.map((transa, transaIndex) =>
										transa && transa.transactionItems ? (
											transa.transactionItems.map((order, orderIndex) => {
											let totalAddOnsPrice = 0;
											
											return (
												<div className="pos-order py-3" key={orderIndex}>
												<div className="pos-order-product">
													<div className="flex-1">
													<div className="row">
														<div className="col-7">
														<div className="fs-6 fw-semibold">{order.itemName}</div>
														{order.addOns && order.addOns.map((addOn, addOnIndex) => {
															const addOnPrice = addOn.price || 0;
															totalAddOnsPrice += addOnPrice;

															return (
															<div key={addOnIndex}>
																- {addOn.key}: {addOn.addOn} - RS {addOnPrice.toFixed(2)}
															</div>
															);
														})}
														</div>
														<div className="col-2">x{order.quantity}</div>
														<div className="col-3 text-body fw-semibold text-end">
														RS {((parseFloat(order.itemPrice) + totalAddOnsPrice) * order.quantity).toFixed(2)}
														</div>
													</div>
													</div>
												</div>
												</div>
											);
											})
										) : (
											<div className="pos-order py-3" key={transaIndex}>
											No records found
											</div>
										)
										)
									) : (
										<div className="pos-order py-3">
										No records found
										</div>
									)}
									</div>

								</PerfectScrollbar>
								
								<div className="pos-sidebar-footer">
								<div className="d-flex align-items-center mb-2">
									<div>Subtotal</div>
									<div className="flex-1 text-end h6 mb-0">RS {getSubTotalPrice()}</div>
								</div>
								<div className="small mb-2">
									{Taxes && Taxes.map((tax, index) => (
									<div key={index} className="d-flex justify-content-between">
										<div>{tax.taxType}: {tax.taxRate} %</div>
										<div className="flex-1 text-end h6 mb-0">RS {getTaxesPrice(tax.taxType)}</div>
									</div>
									))}
								</div>
								<hr className="opacity-1 my-10px" />
								<div className="d-flex align-items-center mb-2">
									<div>Total</div>
									<div className="flex-1 text-end h4 mb-0">RS {getTotalPrice()}</div>
								</div>
								<div className="mt-3">
									<div className="d-flex justify-content-between">
									<a
									href="#/"
									className={`btn btn-theme flex-fill d-flex align-items-center justify-content-center me-2 ${selectedTable.length === 0 ? 'disabled' : ''}`}
									style={{ maxWidth: '150px' }}
									onClick={(e) => {
										e.preventDefault();
										if (selectedTable.length > 0) {
										setModalShow_2(true);
										setPaymentMethod('CASH');
										}
									}}
									disabled={selectedTable.length === 0}
									>
									<span>
									<span className="small fw-bold" style={{ fontSize: '15px', textAlign: 'center' }}>
										MULTI <br /> PAY
									</span>
									</span>
									</a>

									<MultiPaymentModal
									show={modalShow_2}
									onHide={() => setModalShow_2(false)}
									subTotal={getSubTotalPrice()}
									Tax={taxTotals}
									Total={getTotalPrice()}
									paymentMethod={paymentMethod}
									transactionIds={getTTransactionIds()}
									finish={finish}
									cardList={cardList}
									/>
									<a
									href="#/"
									className={`btn btn-theme flex-fill d-flex align-items-center justify-content-center me-2 ${selectedTable.length === 0 ? 'disabled' : ''}`}
									style={{ maxWidth: '150px' }}
									onClick={(e) => {
										e.preventDefault();
										if (selectedTable.length > 0) {
										setModalShow(true);
										setPaymentMethod('CARD');
										}
									}}
									disabled={selectedTable.length === 0}
									>
									<span>
									<span className="small fw-bold" style={{ fontSize: '15px' }}>CARD</span>
									</span>
									</a>
									<a
									href="#/"
									className={`btn btn-theme flex-fill d-flex align-items-center justify-content-center ${selectedTable.length === 0 ? 'disabled' : ''}`}
									style={{ maxWidth: '150px' }}
									onClick={(e) => {
										e.preventDefault();
										if (selectedTable.length > 0) {
										setModalShow(true);
										setPaymentMethod('CASH');
										}
									}}
									disabled={selectedTable.length === 0}
									>
									<span>
									<span className="small fw-bold" style={{ fontSize: '15px' }}>CASH</span>
									</span>
									</a>
									<CounterModal
									show={modalShow}
									onHide={() => setModalShow(false)}
									subTotal={getSubTotalPrice()}
									Tax={taxTotals}
									Total={getTotalPrice()}
									paymentMethod={paymentMethod}
									transactionIds={getTTransactionIds()}
									finish={finish}
									cardList={cardList}
									/>
									</div>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		</>
	)
}

export default PosCounterCheckoutStall;